import { Screen } from 'quasar';
import { computed, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Attribute } from '@/composables/attributesRenderer';
import { type SearchResultsItemProps, useSearchResultsItem } from '@/composables/searchResultsItem';
import { useTheme } from '@/composables/theme';
import translationsListingBadges from '@/i18n/translations/components/listingBadges.json';
import { toThousands } from '@/utils/number';

export const useVarPool = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const searchResultsItem = useSearchResultsItem(props.value as SearchResultsItemProps);

  const { t: tListingBadges } = useI18n(translationsListingBadges);
  const { t: tGlobal } = useI18n();
  const { icons } = useTheme();

  const badges = computed<Attribute[]>(() => [
    {
      icon: icons.new,
      key: 'new',
      value: tListingBadges('newlyListed'),
      visible: searchResultsItem.newlyListedVisible,
    },
    {
      icon: icons.exclusive,
      key: 'exclusive',
      value: tListingBadges('exclusive'),
      visible: !!props.value.listing.listingInfo?.exclusive,
    },
    {
      icon: icons.underDevelopment,
      key: 'under-development',
      value: tListingBadges('underConstruction'),
      visible: props.value.listing.isUnderConstruction,
    },
  ]);
  const screenLtMdOrMap = computed(
    () => Screen.lt.md || searchResultsItem.elListingItemHasClass('listing-item--map')
  );
  const aggregates = computed<Attribute[]>(() => [
    {
      icon: screenLtMdOrMap.value ? icons.size : '',
      key: 'size',
      value: toThousands(props.value.listing.size) + tGlobal('sqm'),
      visible: props.value.listing.size > 0,
    },
    {
      icon: screenLtMdOrMap.value ? icons.floor : '',
      key: 'floor',
      value: searchResultsItem.getFloorsText(false),
      visible: !!searchResultsItem.getFloorsText(true),
    },
    {
      icon: screenLtMdOrMap.value ? icons.bedrooms : '',
      key: 'bedrooms',
      value: screenLtMdOrMap.value
        ? searchResultsItem.bedrooms
        : `${searchResultsItem.bedrooms} ${tGlobal('bedroomShort', searchResultsItem.bedrooms)}`,
      visible: searchResultsItem.bedrooms > 0,
    },
    {
      icon: screenLtMdOrMap.value ? icons.parking : '',
      key: 'parking',
      value: screenLtMdOrMap.value
        ? searchResultsItem.parkingSpaces
        : `${searchResultsItem.parkingSpaces} ${tGlobal(
            'parkingSpace',
            searchResultsItem.parkingSpaces
          )}`,
      visible: searchResultsItem.parkingSpaces > 0,
    },
  ]);

  watch(
    searchResultsItem.elListingItemFavoritesBtnWidth,
    v => {
      document.documentElement.style.setProperty('--favorites-btn-width', v);
    },
    { immediate: true }
  );

  return {
    ...searchResultsItem,
    aggregates,
    badges,
    screenLtMdOrMap,
    tListingBadges,
  };
};
